// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Loader.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Loader.tsx");
  import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import { Spin } from "antd";
const Loader = ({
  loading
}) => loading ? <div className="absolute w-screen h-screen bg-[#00000080] top-[0%] left-[0%] ">
      <Spin spinning={loading} size="large" className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] "></Spin>
    </div> : <></>;
_c = Loader;
export default Loader;
var _c;
$RefreshReg$(_c, "Loader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;